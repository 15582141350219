import type { User } from "@prisma/client"
import ReactGA4 from "react-ga4"
import type { UaEventOptions } from "react-ga4/types/ga4"

import { AE, AnalyticsPayload, AnalyticsPayloadCommon } from "../@types/analytics"
import { DripFeClient } from "../clients/drip_frontend_client"
import _c from "../configs/constants"
import { isTrackableEnvironment } from "../utils/browser_util"
import { checkNull } from "../utils/validation_util"
import { EAnalyticsProvider } from "./../@types/analytics"
import { GTMFEClient } from "./../clients/gtm_frontend_client"
import { LinkedInFeClient } from "./../clients/linkedin_frontend_client"

const debugMode = _c.isDevOrStaging

let Mixpanel: any
let FacebookPixel: any

let userIdentified = false

// --------------------------------------------------------
// Types
// --------------------------------------------------------

export enum EFacebookEvent {
    CompleteRegistration = "CompleteRegistration",
    Contact = "Contact",
    Search = "Search",
    ViewContent = "ViewContent",
    AddPaymentInfo = "AddPaymentInfo",
    StartTrial = "StartTrial",
    Purchase = "Purchase",
    Subscribe = "Subscribe",
    AddToCart = "AddToCart",
    InitiateCheckout = "InitiateCheckout",
}

interface IOptionalGaProperties extends Partial<UaEventOptions> {}

// --------------------------------------------------------
// Controller
// --------------------------------------------------------

if (isTrackableEnvironment()) {
    if (process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID) {
        ReactGA4?.initialize(process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID)
    }
}

class Analytics {
    static isInitialising = false
    static shouldTrack = () => isTrackableEnvironment()
    static isSetup = () => !!((_c.isStaging || FacebookPixel) && ReactGA4 && Mixpanel)
    static getEventName = (event: AE) => `${(event as any)[0]} - ${(event as any)[1]}`
    static lazyInit = async () => {
        if (Analytics.isSetup()) {
            return
        }
        await Analytics.setup()
    }
    static awaitInit = async () => {
        if (!Analytics.isInitialising) {
            return
        }

        const MAX_COUNT = 10
        let counter = 0

        await new Promise<void>((resolve) => {
            const interval = setInterval(() => {
                counter += 1
                if (counter > MAX_COUNT || !Analytics.isInitialising) {
                    clearInterval(interval)
                    resolve()
                }
            }, 500)
        })
    }

    static setup = async () => {
        if (Analytics.isInitialising) {
            await Analytics.awaitInit()
            return
        }
        Analytics.isInitialising = true
        if (process.env.NEXT_PUBLIC_MIXPANEL_API_TOKEN) {
            Mixpanel = (await import("mixpanel-browser")).default
            Mixpanel?.init(process.env.NEXT_PUBLIC_MIXPANEL_API_TOKEN, {
                ignore_dnt: true,
            })
        }
        if (_c.isProduction && process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID) {
            FacebookPixel = (await import("react-facebook-pixel")).default
            FacebookPixel?.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID)
        }
        Analytics.isInitialising = false
    }

    static setProperties = async (properties: any, asSuper = false) => {
        if (!Analytics.shouldTrack()) {
            return
        }

        await Analytics.lazyInit()

        Mixpanel?.people?.set(properties)
        if (asSuper) {
            Mixpanel?.register(properties)
        }

        await DripFeClient.setProperties(properties)
    }

    static trackEvent = async <T extends keyof AnalyticsPayload>(
        event: T,
        data?: AnalyticsPayload[T] & AnalyticsPayloadCommon,
        gaProperties: IOptionalGaProperties = {},
        skipProviders: Array<EAnalyticsProvider> = [],
    ) => {
        const [object, action] = (event as AE).split(/ - (.*)/s)
        if (debugMode) {
            console.info(`[Analytics] ${event}`, data)
        }
        if (!Analytics.shouldTrack()) {
            return
        }

        await Analytics.lazyInit()

        const payload: any = data || {}
        if (_c.isStaging) {
            payload.staging = true
        }
        if (_c.VERSION) {
            payload.version = _c.VERSION
        }

        if (!skipProviders.includes(EAnalyticsProvider.GoogleAnalytics)) {
            try {
                ReactGA4?.event({ ...gaProperties, category: object, action })
                const formattedEventName = event
                    .trim()
                    .replace(/-/g, "")
                    .replace(/ +/g, "_")
                    .toLowerCase()
                ReactGA4?.event(formattedEventName, { ...data, ...gaProperties })
            } catch (error) {
                console.error("Google Analytics - Event Tracking", error)
            }
        }

        if (!skipProviders.includes(EAnalyticsProvider.FacebookPixel)) {
            try {
                FacebookPixel?.trackCustom(event, payload)
            } catch (error) {
                console.error("Facebook Pixel - Event Tracking", error)
            }
        }

        if (!skipProviders.includes(EAnalyticsProvider.Mixpanel)) {
            try {
                Mixpanel?.track(event, payload)
            } catch (error) {
                console.error("Mixpanel - Event Tracking", error)
            }
        }

        if (!skipProviders.includes(EAnalyticsProvider.Drip)) {
            try {
                DripFeClient.trackEvent(event, payload)
            } catch (error) {
                console.error("Drip - Event Tracking", error)
            }
        }

        if (!skipProviders.includes(EAnalyticsProvider.GoogleTagManager)) {
            try {
                GTMFEClient.trackEvent(event, payload)
            } catch (error) {
                console.error("GTM - Custom Event Tracking", error)
            }
        }
    }

    static trackFacebookEvent = async (event: EFacebookEvent, data: Record<any, any> = {}) => {
        if (!Analytics.shouldTrack()) {
            return
        }

        await Analytics.lazyInit()

        try {
            FacebookPixel?.track(event, data)
        } catch (error) {
            console.error("Facebook Pixel - Event Tracking", error)
        }
    }

    static trackLinkedInConversion = async (conversionId: string) => {
        if (!Analytics.shouldTrack()) {
            return
        }

        await Analytics.lazyInit()

        try {
            LinkedInFeClient?.trackConversion(conversionId)
        } catch (error) {
            console.error("LinkedIn Conversion - Tracking", error)
        }
    }

    static trackPage = async (payload: AnalyticsPayload[AE.Page_Visit]) => {
        if (!Analytics.shouldTrack()) {
            return
        }

        await Analytics.lazyInit()

        await Analytics.trackEvent(AE.Page_Visit, payload)

        try {
            ReactGA4?.set(payload)
            ReactGA4?.send({ hitType: "pageview", page: payload.path })
        } catch (error) {
            console.error("Google Analytics - Page Tracking", error)
        }

        try {
            FacebookPixel?.pageView()
        } catch (error) {
            console.error("Facebook Pixel - Page Tracking", error)
        }
    }

    static identifyGuest = async () => {
        if (!Analytics.shouldTrack()) {
            return
        }
        await Analytics.lazyInit()
    }

    static identifyUser = async (user: Partial<User>, needsAlias = false) => {
        if (!Analytics.shouldTrack()) {
            return
        }

        await Analytics.lazyInit()

        if (!!checkNull(user) || !!userIdentified) {
            return
        }

        if (needsAlias) {
            Mixpanel?.alias(user.id)
        } else {
            Mixpanel?.identify(user.id)
        }

        const properties: Record<any, any> = {
            $first_name: user.firstName,
            $last_name: user.lastName,
            $created: user.createdAt,
            $email: user.email,
            creator: user.isCreator,
        }

        if (user.isCreator) {
            properties["role"] = "creator"
            properties["slug"] = user.slug
        }

        try {
            Mixpanel?.people.set(properties)
        } catch (error) {
            console.error("Mixpanel - Identify User", error)
        }

        try {
            await DripFeClient.identifyUser(user)
        } catch (error) {
            console.error("Drip - Identify User", error)
        }

        try {
            ReactGA4?.initialize(process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID!, {
                gaOptions: {
                    userId: user.id,
                },
            })
            ReactGA4?.gtag("config", process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID, {
                allow_enhanced_conversions: true,
                user_id: user.id,
            })
        } catch (error) {
            console.error("Google Analytics - Identify User", error)
        }

        try {
            FacebookPixel?.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID, {
                external_id: user.id,
            })
        } catch (error) {
            console.error(error)
            throw error
        }

        await Analytics.setSessionParams(user)
        userIdentified = true
    }

    static clearSession = async () => {
        if (!Analytics.shouldTrack()) {
            return
        } else {
            await Analytics.lazyInit()
        }
        Mixpanel?.reset()
    }

    static setSignUpParams = async () => {
        await Analytics.setProperties({
            "Initial Platform": "web",
            "Initial Device": "web",
        })
    }

    static setSessionParams = async (user?: Partial<User>) => {
        const additionalParams: { [key: string]: string } = {}
        await Analytics.setProperties({
            "Internal Id": user?.id,
            "Most Recent Device": "web",
            ...additionalParams,
        })
    }
}

export default Analytics
